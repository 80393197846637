import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild, computed, inject, signal, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UtilityService } from '../../../services/utility.service';
import { AppService } from '../../../services/app.service';
import { BtnComponent, EVFunctions, ModalComponent, ModalComponents, PSDirective } from 'ets-fe-ng-sdk';
import { MatTreeModule } from '@angular/material/tree';
import { AppRouteService } from '../../../services/route.service';
import { filter, tap } from 'rxjs';
import { MenuFacadeService } from '../../../facades/menu.facade.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { environment } from '../../../../environments/environment';
import { LayoutsService } from '../layouts.service';
import { MatMenuModule } from '@angular/material/menu';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'lib-horizontal-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    BtnComponent,
    MatMenuModule,
    MatSidenavModule,
    PSDirective,
    MatListModule,
    MatTooltipModule,
    ModalComponents,
  ],
  templateUrl: './horizontal-layout.component.html',
  styleUrls: ['../dashboard-layout/dashboard-layout.component.scss', './horizontal-layout.component.scss'],
})
export class HorizontalLayoutComponent {
  public uS = inject(UtilityService);
  public authS = inject(AuthenticationService);
  public lS = inject(LayoutsService);
  public appService = inject(AppService);
  public appRoute = inject(AppRouteService);
  public router = inject(Router);
  public menuF = inject(MenuFacadeService);
  readonly env = signal(environment);

  readonly profileDesc = computed<string>(() =>
    [
      [
        'Name',
        EVFunctions.strConcatenator2(
          this.authS.candidate()?.firstName,
          this.authS.candidate()?.lastName,
          ' ',
        ),
      ],
    ]
      .map(([label, value]) => `${label}: ${value || '-'}`)
      .join('\n'),
  );

  readonly userName = computed<string>(() =>
    EVFunctions.strConcatenator2(this.authS.candidate()?.firstName, this.authS.candidate()?.lastName, ' '),
  );

  readonly menu = computed(() => this.appService.systemMenu());

  @ViewChild('bodyCont') bodyContRef?: ElementRef<HTMLDivElement>;

  readonly pSBoxRef = viewChild(PSDirective);
  readonly routeChangedSignal = toSignal(
    this.appRoute.completed.pipe(
      tap(() => {
        if (!!this.bodyContRef) this.bodyContRef!.nativeElement.scroll(0, 0);
        setTimeout(() => {
          this.pSBoxRef()?.update(); 
        }, 500);
        setTimeout(() => {
          this.pSBoxRef()?.update(); 
        }, 1000);
      }),
    ),
  );
  protected isHomePath = '/home';
  readonly isHome = computed<boolean>(() => {
    this.routeChangedSignal();
    return location.pathname == this.isHomePath;
  });
  readonly id = 'bod' + this.uS.genRandomID;
  readonly breadCrumb = toSignal(this.menuF.selectBreadcrumb(), { initialValue: [] });

  constructor() {
    // this.leveller(this.appService.menu);
    // this.dataSource.data = this.appService.menu;
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  back() {
    this.uS.back();
  }
}
